import React, { Fragment, useState, useEffect, useMemo, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTable, useSortBy } from "react-table";
import { Row, Col, Card, Table } from "react-bootstrap";
import { Link } from "react-router-dom";

import { useSelector } from "react-redux";
import { Commissiondata_Wyz } from "../../../../services/api_function";

const CommissionDataWyz = () => {
  const location = useLocation();
  const FromUrl = new URLSearchParams(location.search).get("user");
  const [user, setUser] = useState(FromUrl);
  const [userData, setUserData] = useState([]);

  const isInitialRender = useRef(true);
  const User = useMemo(() => user, [user]);
  const token = useSelector((state) => state.auth.auth.token);
  useEffect(() => {
    Commissiondata_Wyz(User, token)
      .then((response) => {
      
        setUserData(response?.data);

      })
      .catch((error) => {
        console.error("Error fetching team data:", error);
      });
  }, [User]);


  const navigate = useNavigate();

  return (
    <Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Header
              style={{ background: "black", border: "1px solid white" }}
            >
              <i
                class="fas fa-circle-left "
                style={{ fontSize: "2rem" }}
                onClick={() => navigate(-1)}
              ></i>
              <Card.Title style={{ color: "white", margin: "auto" }}>
                Commission
              </Card.Title>
            </Card.Header>
            <Card.Body
              style={{ background: "black", border: "1px solid white" }}
            >
              <Table
                responsive
                style={{
                  background: "black",
                  color: "white",
                  borderBottom: "1px solid white",
                }}
              >
                <thead>
                  <tr>
                    <th>
                      <strong>Amount usdt</strong>
                    </th>
                    <th>
                      <strong>Amount WYZ</strong>
                    </th>
                    <th>
                      <strong>Total Income</strong>
                    </th>
                    <th>
                      <strong>Total Withdraw</strong>
                    </th>
                    <th>
                      <strong>Referal Income</strong>
                    </th>
                    <th>
                      <strong>Level Income</strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td >
                      {(userData?.users?.wyz_topup_amount_usdt ?? 0).toFixed(2)}
                    </td>
                    <td >
                      {(userData?.users?.wyz_topup_amount ?? 0).toFixed(2)}
                    </td>
                    <td >
                      {(userData?.users?.wyz_totalIncome ?? 0).toFixed(2)}
                    </td>
                    <td >
                      {(userData?.users?.wyz_totalWithdraw ?? 0).toFixed(2)}
                    </td>
                    <td>
                      {(userData?.users?.wyz_referalIncome ?? 0).toFixed(2)}
                    </td>
                    <td >
                      {(userData?.users?.wyz_levelIncome ?? 0).toFixed(2)}
                    </td>
                   
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default CommissionDataWyz;
